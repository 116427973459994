import React from 'react';
import TeamMemberCard from '../../components/TeamMember';
import Wrapper from './team-members-styles';
import { DatoCodeDescription, DatoPicture } from '../../services/interfaces';

interface TeamMemberItem {
  name: string;
  position: DatoCodeDescription;
  image: DatoPicture;
  quote: string;
}
interface Props {
  members: Array<TeamMemberItem>;
}

const TeamMembersList = (props: Props): JSX.Element => {
  return (
    <Wrapper>
      {props.members.map((member: TeamMemberItem) => (
        <TeamMemberCard
          key={`member-item-${member.name}`}
          name={member.name}
          jobPosition={member.position}
          profilePicture={member.image}
          description={member.quote}
        />
      ))}
    </Wrapper>
  );
};

export default TeamMembersList;
