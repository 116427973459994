import styled from 'styled-components';
import config from '../../components/config';

export default styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    background: ${config.palette.white};
    border-radius: 0.6rem;
    box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.1);
    margin-top: 3.2rem;
    text-decoration: none;
    width: calc(33% - (3.2rem * 2 / 3));

    h3 {
      padding: 1.6rem 2.4rem;
    }

    & > article {
      border-radius: 0.6rem 0.6rem 0 0;
      overflow: hidden;

      & > img {
        border-radius: 0.6rem 0.6rem 0 0;
        height: 20rem;
        object-fit: cover;
        transform: scale(1.05);
        transition: transform 0.3s ease-in-out;
        width: 100%;
      }
    }

    &:hover > article > img {
      border-radius: 0.6rem;
      transform: scale(1.15);
    }

    & > div {
      padding: 1.6rem 2.4rem;

      & > p {
        color: ${config.palette.darkBlueTransparent('0.8')};
        margin-top: 0.4rem;
      }
    }

    @media (max-width: ${config.resolutions.mobileL}) {
      margin-top: 2.4rem;
      width: 100%;
    }
  }
`;
