import React, { useState } from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import Layout from '../atoms/layout';
import { Title2 } from '../atoms/headers';
import Section, {
  SectionBar,
  DivArrow,
  DivLines,
  ArticleLine
} from './Carousel';
import CarouselProps from '../interfaces/Carousel';
import { Text1, Text3 } from '../atoms/body';

function Carousel(props: CarouselProps): JSX.Element {
  const [indexCard, changeIndexCard] = useState(0);
  const { cards, title, subtitle, backgroundColor, ...other } = props;

  const elementsCards = cards.map(
    (card: { titleCard: string; text: string[]; url: string }) => {
      const { titleCard, text, url } = card;
      return (
        <>
          <article>
            <Text1 as="h3">{titleCard}</Text1>
            {text.map(pharse => (
              <Text3 key={uniqid()} as="p">
                {pharse}
              </Text3>
            ))}
          </article>
          <img src={url} alt={titleCard} />
        </>
      );
    }
  );

  const changeCard = (num: number): void => {
    const next = indexCard + num;
    if (next < 0) {
      changeIndexCard(elementsCards.length - 1);
    } else if (next >= elementsCards.length) {
      changeIndexCard(0);
    } else {
      changeIndexCard(next);
    }
  };

  return (
    <Layout {...other}>
      <Title2 as="h2">{title}</Title2>
      {subtitle ? <Text3 as="p">{subtitle}</Text3> : <span />}
      <Section backgroundColor={backgroundColor}>
        {elementsCards[indexCard]}
      </Section>
      <SectionBar>
        <DivLines>
          {elementsCards.map((_, i: number) => (
            <ArticleLine
              totalItems={elementsCards.length}
              key={uniqid()}
              className={classNames({ active: i === indexCard })}
            />
          ))}
        </DivLines>
        <DivArrow>
          <img
            onClick={(): void => changeCard(-1)}
            src="/assets/icons/arrows/arrow-left.svg"
            alt="arrow-left"
          />
          <img
            onClick={(): void => changeCard(1)}
            src="/assets/icons/arrows/arrow-right.svg"
            alt="arrow-right"
          />
        </DivArrow>
      </SectionBar>
    </Layout>
  );
}

export default Carousel;
