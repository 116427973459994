import styled from 'styled-components';
import config from '../config';
import { hexStringToRGB } from '../../utils/css';

export default styled.section<{ backgroundColor: string }>`
  margin-top: 3.2rem;
  padding: 4.8rem;
  background-color: ${props => props.backgroundColor};
  display: flex;
  align-items: center;
  border-radius: 0.6rem;

  img {
    max-width: 50%;
    border-radius: 0.6rem;
  }

  article {
    margin-right: 4.8rem;

    p {
      margin-top: 1.6rem;
      opacity: 0.6;
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-top: 1.6rem;
    flex-direction: column-reverse;
    padding: 0;
    background-color: initial;

    img {
      max-width: 100%;
    }

    article {
      margin: 0;
      margin-top: 2rem;
    }
  }
`;
export const SectionBar = styled.section`
  display: flex;
  margin-top: 3rem;
  width: 100%;
`;

export const DivArrow = styled.div`
  display: flex;

  img {
    cursor: pointer;
  }

  img:first-child {
    margin-right: 2.4rem;
  }
`;

export const DivLines = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 6rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-right: 2.8rem;
  }
`;

export const ArticleLine = styled('article')<{ totalItems: number }>`
  width: ${props => 100 / props.totalItems}%;
  height: 0.2rem;
  margin-right: 0.4rem;
  background-color: rgba(${hexStringToRGB(config.palette.darkBlue)}, 0.4);

  &.active {
    background-color: ${config.palette.darkBlue};
  }
`;
