import styled from 'styled-components';

export default styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3.2rem;

  & > article:last-child {
    margin-right: 0;
  }
`;
