import styled from 'styled-components';
import config from '../../components/config';

const TheAlgorithmIsFemale = styled.section`
  .header-ctas {
    a {
      border: 0.1rem solid ${config.palette.orangeTransparent('0.1')};
      border-radius: ${config.borderRadius};
      color: ${config.palette.orange};
      cursor: pointer;
      display: inline-block;
      font-size: 1.6rem;
      line-height: 150%;
      margin-top: 3.2rem;
      padding: 2.8rem 2.4rem;
      text-decoration: none;
      transition: 0.3s ease-in-out color;

      &:hover {
        color: ${config.palette.orangeTransparent('0.9')};
      }

      &.applyNow {
        background: ${config.palette.lightBlue};
        color: ${config.palette.white};
        font-size: 2rem;
        left: 2.4rem;
        line-height: 160%;
        margin-right: 2.6rem;
        padding: 2.4rem 13.2rem;
        transition: 0.3s ease-in-out background;

        &:hover {
          background: ${config.palette.lightBlueHover};
        }
      }
    }
  }

  .taif-section {
    margin-bottom: 4.8rem;

    h2 {
      margin-bottom: 1.6rem;
      width: 100%;
    }

    p {
      color: ${config.palette.darkBlueTransparent('0.72')};
      margin-bottom: 3.2rem;
    }

    &.reason-behind {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > div {
        width: 65%;
      }
    }

    &.quick-facts {
      .list-item {
        display: flex;
        margin-bottom: 3.2rem;

        &:last-child {
          margin-bottom: 0;
        }

        & > svg {
          height: 2.4rem;
          margin-right: 1.6rem;
          width: 2.4rem;
        }
      }
    }
  }

  .ironwomen-companies {
    margin-top: 5rem;
  }

  .ironwomen {
    margin-bottom: 8rem;
    margin-top: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    .header-ctas {
      a {
        border-color: ${config.palette.orange};
        border-width: 0.2rem;
        padding: 1.2rem 0;
        text-align: center;
        width: 100%;

        &.applyNow {
          border-color: ${config.palette.lightBlue};
          bottom: 1.6rem;
          margin-right: 0;
          padding: 1.2rem 0;
          position: fixed;
          width: calc(100% - 4.8rem);
          z-index: 100;
        }
      }
    }

    .taif-section {
      h2 {
        font-size: 2.2rem;
        letter-spacing: -0.1rem;
        line-height: 120%;
        margin-bottom: 1.2rem;
      }

      p {
        margin-bottom: 1.6rem;
      }

      &.reason-behind {
        & > div {
          width: 100%;
        }
      }

      &.quick-facts {
        .list-item {
          & > svg {
            height: 2.4rem;
            width: 7.2rem;
          }
        }
      }
    }
  }
`;

const Stats = styled.section`
  display: flex;
  width: 35%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > section > section:first-child {
    margin-bottom: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
  }
`;

export { TheAlgorithmIsFemale, Stats };
