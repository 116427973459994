import React from 'react';
import { graphql } from 'gatsby';
import withI18next from '../../i18n/TemplateI18n';
import { windowDefined } from '../../lib/utils';
import { getLinkByPageName } from '../../lib/links';
import Layout from '../../components/atoms/layout';
import Title2 from '../../components/atoms/headers/title2';
import StatsList from '../../components/StatsList';
import Text3 from '../../components/atoms/body/text3';
import Text4 from '../../components/atoms/body/text4';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Carousel from '../../components/Carousel';
import StudentCompanies from '../../components/StudentCompanies';
import ContextualCta from '../../components/ContextualCta';
import Footer from '../../components/Footer';
import { TheAlgorithmIsFemale, Stats } from './styles';
import AboutUsProps from '../../interface/template';
import CampusList from './campus-list';
import TeamMemberList from './team-members-list';
import CheckBox from '../../../static/assets/icons/checkbox/checkbox-orange.svg';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

const WomenInTech: React.SFC<any> = (props: AboutUsProps) => {
  const { t, pageContext } = props;
  const { pageName } = pageContext;
  const { userLocation } = useUserLocation();

  const transitionToEvents = (): void => {
    const eventsSection = document.getElementById('events-list');
    if (eventsSection && windowDefined()) {
      window.scrollTo({
        left: 0,
        top: eventsSection.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <TheAlgorithmIsFemale>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header showBackground={false} pageName={pageName}>
        <PageTitle
          title={t('women-in-tech:womenInTech.mainHeadline.title')}
          subtitle={t('women-in-tech:womenInTech.mainHeadline.subtitle')}
        >
          <div className="header-ctas">
            <a
              href={getLinkByPageName('allCourses/application')}
              className="applyNow"
            >
              {t('women-in-tech:womenInTech.mainHeadline.mainCta')}
            </a>
            <a onClick={transitionToEvents}>
              {t('women-in-tech:womenInTech.mainHeadline.secondaryCta')}
            </a>
          </div>
        </PageTitle>
      </Header>

      <Layout className="taif-section reason-behind">
        <Title2 as="h2">{t('women-in-tech:womenInTech.context.title')}</Title2>
        <div>
          <Text3 as="p">
            {t('women-in-tech:womenInTech.context.description.01')}
          </Text3>
          <Text3 as="p">
            {t('women-in-tech:womenInTech.context.description.02')}
          </Text3>
          <Text3 as="p">
            {t('women-in-tech:womenInTech.context.description.03')}
          </Text3>
          <Text3 as="p">
            {t('women-in-tech:womenInTech.context.description.04')}
          </Text3>
        </div>
        <Stats>
          <StatsList context="women_in_tech" columns={1} />
        </Stats>
      </Layout>

      <Layout className="taif-section quick-facts">
        <Title2 as="h2">
          {t('women-in-tech:womenInTech.quickFacts.title')}
        </Title2>
        <Text3 as="p">
          {t('women-in-tech:womenInTech.quickFacts.description')}
        </Text3>
        <Text4 as="p" className="list-item">
          <CheckBox />
          {t('women-in-tech:womenInTech.quickFacts.list.01')}
        </Text4>
        <Text4 as="p" className="list-item">
          <CheckBox />
          {t('women-in-tech:womenInTech.quickFacts.list.02')}
        </Text4>
        <Text4 as="p" className="list-item">
          <CheckBox />
          {t('women-in-tech:womenInTech.quickFacts.list.03')}
        </Text4>
      </Layout>

      <Layout id="events-list" className="taif-section">
        <Title2 as="h2">{t('women-in-tech:womenInTech.events.title')}</Title2>
        <Text3 as="p">
          {t('women-in-tech:womenInTech.events.description')}
        </Text3>
        <CampusList
          links={{
            ams: 'https://www.eventbrite.com/o/ironhack-amsterdam-17314560407',
            bcn: 'https://www.eventbrite.com/o/ironhack-barcelona-13492633894',
            ber: 'https://www.eventbrite.com/o/ironhack-germany-16467596875',
            lis: 'https://www.eventbrite.com/o/ironhack-lisbon-18246965875',
            mad: 'https://www.eventbrite.com/o/ironhack-madrid-8390507274',
            mex: 'https://www.eventbrite.com/o/ironhack-mexico-15214277626',
            sao: 'https://www.eventbrite.com/o/ironhack-sao-paulo-17392954350',
          }}
        />
      </Layout>
      <Carousel
        className="carousel"
        backgroundColor="#FEF4E6"
        title={t('women-in-tech:womenInTech.testimonials.title')}
        cards={[
          {
            titleCard: t(
              'women-in-tech:womenInTech.testimonials.contents.01.name'
            ),
            text: [
              t('women-in-tech:womenInTech.testimonials.contents.01.quote'),
            ],
            url: '/assets/the-algorithm-is-female/lupina-iturriaga.jpg',
          },
          {
            titleCard: t(
              'women-in-tech:womenInTech.testimonials.contents.02.name'
            ),
            text: [
              t('women-in-tech:womenInTech.testimonials.contents.02.quote'),
            ],
            url: '/assets/the-algorithm-is-female/quan-zhou.jpg',
          },
          {
            titleCard: t(
              'women-in-tech:womenInTech.testimonials.contents.03.name'
            ),
            text: [
              t('women-in-tech:womenInTech.testimonials.contents.03.quote'),
            ],
            url: '/assets/the-algorithm-is-female/raquel-fernandez.jpg',
          },
        ]}
      />
      <Layout className="ironwomen-companies">
        <StudentCompanies
          title={t('women-in-tech:womenInTech:ironwomenCompanies')}
        />
      </Layout>
      <Layout className="ironwomen">
        <Title2 as="h2">{t('women-in-tech:womenInTech.preach.title')}</Title2>
        <TeamMemberList
          members={[
            {
              name: t('women-in-tech:womenInTech.preach.contents.01.name'),
              position: {
                description: t(
                  'women-in-tech:womenInTech.preach.contents.01.position'
                ),
              },
              image: {
                url: '/assets/staff/members/manon-pellat.jpg',
              },
              quote: t('women-in-tech:womenInTech.preach.contents.01.quote'),
            },
            {
              name: t('women-in-tech:womenInTech.preach.contents.02.name'),
              position: {
                description: t(
                  'women-in-tech:womenInTech.preach.contents.02.position'
                ),
              },
              image: {
                url: '/assets/staff/members/claudia-rivera.jpg',
              },
              quote: t('women-in-tech:womenInTech.preach.contents.02.quote'),
            },
            {
              name: t('women-in-tech:womenInTech.preach.contents.03.name'),
              position: {
                description: t(
                  'women-in-tech:womenInTech.preach.contents.03.position'
                ),
              },
              image: { url: '/assets/staff/members/aleksandra-boskovic.JPG' },
              quote: t('women-in-tech:womenInTech.preach.contents.03.quote'),
            },
            {
              name: t('women-in-tech:womenInTech.preach.contents.04.name'),
              position: {
                description: t(
                  'women-in-tech:womenInTech.preach.contents.04.position'
                ),
              },
              image: {
                url: '/assets/staff/members/kimberly-krueger.jpg',
              },
              quote: t('women-in-tech:womenInTech.preach.contents.04.quote'),
            },
            {
              name: t('women-in-tech:womenInTech.preach.contents.05.name'),
              position: {
                description: t(
                  'women-in-tech:womenInTech.preach.contents.05.position'
                ),
              },
              image: {
                url: '/assets/staff/members/rocio-perez.jpg',
              },
              quote: t('women-in-tech:womenInTech.preach.contents.05.quote'),
            },
            {
              name: t('women-in-tech:womenInTech.preach.contents.06.name'),
              position: {
                description: t(
                  'women-in-tech:womenInTech.preach.contents.06.position'
                ),
              },
              image: {
                url: '/assets/staff/members/floor-banga.jpg',
              },
              quote: t('women-in-tech:womenInTech.preach.contents.06.quote'),
            },
          ]}
        />
      </Layout>
      <ContextualCta />
      <Footer page={pageName} />
    </TheAlgorithmIsFemale>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(women-in-tech)|(staff)|(breadcrumbs)|(seo)|(contextual-cta)|(eyebrow)|(banner)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(WomenInTech);
