import React from 'react';
import Text2 from '../../components/atoms/body/text2';
import CampusListWrapper from './campus-list-styles';
import campuses from '../../campus.json';

interface CampusLinks {
  [key: string]: string;
}

interface Props {
  links: CampusLinks;
}

const CampusList: React.SFC<any> = (props: Props): JSX.Element => {
  return (
    <CampusListWrapper>
      {Object.keys(props.links).map((campusCode: string) => {
        const currentCampus = campuses.find(
          (campus: { code: string }) => campus.code === campusCode
        );
        return (
          <a
            href={props.links[campusCode]}
            key={campusCode}
            target="_blank"
            rel="noreferrer noopener"
          >
            <article>
              <img
                src={currentCampus.image}
                alt={`Ironhack ${currentCampus.name}`}
              />
            </article>
            <Text2 as="h3">{`${currentCampus.name}, ${currentCampus.country}`}</Text2>
          </a>
        );
      })}
    </CampusListWrapper>
  );
};

export default CampusList;
